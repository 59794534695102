<template>
  <div class="aboutDebris flex-row">
    <div v-if="debrisLanguageType == 1" class="container-left flex-col">
      <span>关于DEBRIS</span>
      <span
        >Debris Studio，来自中国深圳，致力于打造具有独特风格的原创动画。</span
      >
      <span
        >Debris概念源起于我们对这个世界的日常感受，仍旧希望在充满不确定性的时代中寻找确定性，致力于让人们在碎片化的信息洪流中保持独立思考，引导人们在多元价值领域中寻求自我救赎，不断追求真实感，重拾自我、质感和真实关系。</span
      >
      <span
        >我们期望Debris成为一个文化象征，从动画这一媒介出发，未来通过跨界合作与多元创意，将Debris这一独特的艺术内核融入到各类艺术作品中，积极与各界人士合作，共同为打造一个展现人类坚韧、自由与创新精神的Debris艺术世界而努力。</span
      >
    </div>
    <div
      v-if="debrisLanguageType == 2"
      class="container-left en-language flex-col"
    >
      <span>About DEBRIS</span>
      <span
        >Debris Studio, From Shenzhen, China, committed to creating original
        animation with a unique style.</span
      >
      <span
        >DEBRIS – The concept originates from our daily world experience. We
        hope to find certainty in an era full of doubt. We are committed to
        enabling independent thinking within torrents of fragmented information,
        and guiding people to seek their true selves in this present of ranged
        values. Our ideals include redemption, constant pursuit of authenticity,
        regaining of self, texture and real relationships.</span
      >
      <span
        >We expect DEBRIS to become a cultural symbol. Starting from the medium
        of animation, we will integrate the singular artistic core of DEBRIS
        into various works of art through boundaryless collaboration and diverse
        creativity in the future, coacting with those from all walks of life to
        create tangibles for the DEBRIS art world that show the tenacity,
        freedom and innovative spirit of human beings.</span
      >
    </div>
    <div class="container-right">
      <video
        disablePictureInPicture="true"
        controlslist="nodownload  noremoteplayback"
        oncontextmenu="return false;"
        id="my-video"
        src="https://debrisanimation.com/resource/video.mp4"
        poster="../assets/image/others/poster.jpg"
        controls
        :muted="false"
        class="video"
        style="object-fit: contain"
      ></video>
      <div class="play-view" @click="playPauseBtn">
        <svg-icon
          v-if="isPlayIng"
          class="pause-svg"
          iconClass="pause"
        ></svg-icon>
        <svg-icon v-else class="play-svg" iconClass="play"></svg-icon>
      </div>
    </div>
    <img
      class="close"
      @click="goBack"
      src="../assets/icon/close_b.png"
      alt=""
    />
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      isPlayIng: false,
    };
  },
  created() {},
  computed: {
    debrisLanguageType() {
      return this.$store.state.debrisLanguageType;
    },
  },
  mounted() {
    const video = document.getElementById("my-video");
    // 监听视频播放事件
    video.addEventListener("play", () => {
      console.log("视频正在播放！");
      this.isPlayIng = true;
    });
    // 监听视频暂停事件
    video.addEventListener("pause", () => {
      console.log("视频已经暂停。");
      this.isPlayIng = false;
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    playPauseBtn() {
      var video = document.getElementById("my-video");
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    },
  },
};
</script>

<style lang="scss">
//全屏按钮
video::-webkit-media-controls-fullscreen-button {
  display: none;
}

//播放按钮
video::-webkit-media-controls-play-button {
  display: none;
}

video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}

//观看的当前时间
// video::-webkit-media-controls-current-time-display {
//   display: none;
// }

//剩余时间
// video::-webkit-media-controls-time-remaining-display {
//   display: none;
// }

//音量按钮
// video::-webkit-media-controls-mute-button {
//   display: none;
// }

//音量的控制条
// video::-webkit-media-controls-volume-slider {
//   display: none;
// }

.aboutDebris {
  width: 100%;
  height: 8.16rem;
  background-color: #c5c5c5;
  position: relative;

  .container-left {
    width: 4.69rem;
    height: 100%;
    padding: 0.26rem;

    span {
      display: inline-block;
      width: 3.35rem;
      font-size: 0.2rem;
      line-height: 0.24rem;
      margin-top: 0.28rem;
      &:nth-child(1) {
        margin-top: 0;
        margin-bottom: 0.47rem;
        font-size: 0.4rem;
        line-height: 0.48rem;
        font-family: "Alibaba-PuHuiTi-Bold";
      }
    }
  }

  .en-language {
    span {
      width: 3.96rem;
      font-size: 0.16rem;
      font-family: "Poppins";
      &:nth-child(1) {
        font-family: "Poppins Bold";
      }
    }
  }

  .container-right { 
    width: calc(100% - 4.69rem);
    height: 100%;
    position: relative;

    &:hover {
      .play-view {
        display: block;
      }
    }

    .video {
      width: 100%;
      height: 100%;
    }

    .play-view {
      width: 1.24rem;
      height: 1.24rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;

      .pause-svg,
      .play-svg {
        width: 100%;
        height: 100%;
        color: #ffffff;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          color: #33774a;
        }
      }
    }
  }

  .close {
    width: 0.25rem;
    height: 0.25rem;
    position: absolute;
    left: 0.23rem;
    bottom: 0.23rem;
    cursor: pointer;
  }
}
</style>
